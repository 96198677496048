import React from "react";
import Header from "../components/header";
import "./about.css";
import mecoffee from "../assets/me&coffee.webp";
import lovers from "../assets/Cali&Nick Lovers Ending.webp";
import pose from "../assets/IMG_0211.webp";
import { Parallax } from "react-scroll-parallax";
import { basicMe, schoolMe, skatingMe } from "../assets/aboutMe";
import "@fontsource/abril-fatface";

function About() {
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 660;

  React.useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);
  return (
    <div>
      <Header></Header>
      <Parallax speed={0}>
        <div
          className={`About-container${width < breakpoint ? "-mobile" : ""}`}
        >
          <img
            src={mecoffee}
            alt=""
            style={{
              maxHeight: "50%",
              maxWidth: "50%",
              borderRadius: "5%",
              // padding: 20,
            }}
          ></img>
          <div
            style={{
              height: "100%",
              width: "100%",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              justifyItems: "center",
              padding: 10,
            }}
          >
            <div
              elevation={6}
              style={{
                padding: 5,
                whiteSpace: "pre-wrap",
                fontSize: 25,
                color: "#D2D2D2",
                fontFamily: "acron",
                wordSpacing: 1.5,
              }}
            >
              {schoolMe}
            </div>
          </div>
        </div>
      </Parallax>
      <Parallax speed={10}>
        {width > breakpoint ? (
          <div className="About-container">
            <div
              style={{
                height: "100%",
                width: "100%",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                justifyItems: "center",
                padding: 10,
                zIndex: 1,
              }}
            >
              <div
                elevation={6}
                style={{
                  padding: 5,
                  whiteSpace: "pre-wrap",
                  fontSize: 25,
                  color: "#D2D2D2",
                  fontFamily: "acron",
                  wordSpacing: 1.5,
                }}
              >
                {skatingMe}
              </div>
            </div>
            <img
              src={lovers}
              alt=""
              style={{
                maxHeight: "60%",
                maxWidth: "60%",
                padding: 20,
                zIndex: -1,
              }}
            ></img>
          </div>
        ) : (
          <div className="About-container-mobile">
            <img
              src={lovers}
              alt=""
              style={{
                maxHeight: "60%",
                maxWidth: "60%",
              }}
            ></img>
            <div
              style={{
                height: "100%",
                width: "100%",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                elevation={6}
                style={{
                  padding: 10,
                  whiteSpace: "pre-wrap",
                  fontSize: 25,
                  color: "#D2D2D2",
                  fontFamily: "acron",
                  wordSpacing: 1.5,
                }}
              >
                {skatingMe}
              </div>
            </div>
          </div>
        )}
      </Parallax>
      <Parallax speed={0}>
        <div
          className={`About-container${width < breakpoint ? "-mobile" : ""}`}
        >
          <img
            src={pose}
            alt=""
            style={{
              maxHeight: "60%",
              maxWidth: "60%",
              padding: 20,
            }}
          ></img>
          <div
            style={{
              height: "100%",
              width: "100%",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              justifyItems: "center",
            }}
          >
            <div
              elevation={6}
              style={{
                padding: 10,
                whiteSpace: "pre-wrap",
                fontSize: 25,
                color: "#D2D2D2",
                fontFamily: "acron",
                wordSpacing: 1.5,
              }}
            >
              {basicMe}
            </div>
          </div>
        </div>
      </Parallax>
    </div>
  );
}

export default About;
