import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./header.css";
import "@fontsource/abril-fatface";
import "@fontsource/tajawal";

const trans = { background: "transparent" };
const highlight = { background: "#6d5bf3" };

function HeaderMobile() {
  const [homeBColor, setHomeBColor] = useState(trans);
  const [bColor, setbColor] = useState(trans);
  const [b1Color, setb1Color] = useState(trans);
  const [b2Color, setb2Color] = useState(trans);
  const [b3Color, setb3Color] = useState(trans);

  return (
    <div className="App-header">
      <div className="Button-container">
        <Link
          to="/"
          style={homeBColor}
          className="Button"
          onMouseEnter={() => setHomeBColor(highlight)}
          onMouseLeave={() => setHomeBColor(trans)}
        >
          <p
            style={{
              color: "white",
              borderWidth: 0,
              width: "100%",
              height: "100%",
              fontSize: 15,
              fontFamily: "Tajawal",
            }}
          >
            Home
          </p>
        </Link>
        <Link
          to="/about"
          style={bColor}
          className="Button"
          onMouseEnter={() => setbColor(highlight)}
          onMouseLeave={() => setbColor(trans)}
        >
          <p
            style={{
              color: "white",
              borderWidth: 0,
              width: "100%",
              height: "100%",
              fontSize: 15,
              fontFamily: "Tajawal",
            }}
          >
            About Me
          </p>
        </Link>
        <Link
          to="/project"
          style={b1Color}
          className="Button"
          onMouseEnter={() => setb1Color(highlight)}
          onMouseLeave={() => setb1Color(trans)}
        >
          <p
            style={{
              backgroundColor: "transparent",
              color: "white",
              borderWidth: 0,
              width: "100%",
              height: "100%",
              fontSize: 15,
              fontFamily: "Tajawal",
            }}
          >
            Projects
          </p>
        </Link>
        <Link
          to="/experience"
          style={b2Color}
          className="Button"
          onMouseEnter={() => setb2Color(highlight)}
          onMouseLeave={() => setb2Color(trans)}
        >
          <p
            style={{
              backgroundColor: "transparent",
              color: "white",
              borderWidth: 0,
              width: "100%",
              height: "100%",
              fontSize: 15,
              fontFamily: "Tajawal",
            }}
          >
            Academics
          </p>
        </Link>
        <Link
          to="/contact"
          style={b3Color}
          className="Button"
          onMouseEnter={() => setb3Color(highlight)}
          onMouseLeave={() => setb3Color(trans)}
        >
          <p
            style={{
              backgroundColor: "transparent",
              color: "white",
              borderWidth: 0,
              width: "100%",
              height: "100%",
              fontSize: 15,
              fontFamily: "Tajawal",
            }}
          >
            Contact
          </p>
        </Link>
      </div>
    </div>
  );
}

export default HeaderMobile;
