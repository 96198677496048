import React from "react";
import Divider from "@mui/material/Divider";
import Header from "../components/header";
import ucsc from "../assets/ucscsign.webp";
import mpc from "../assets/mpc logo.webp";
import ucscLogo from "../assets/ucsc-logo.webp";
import MPCAward from "../assets/MPC-Award.webp";
import calcAward from "../assets/Calc-Award.webp";
import MCLogo from "../assets/Mission_College_Logo.webp";
import MPCLogo from "../assets/MPC-logo.webp";
import CSMLogo from "../assets/CSM-logo.webp";
import "./experience.css";
import {
  MPCMe,
  STEMTutor,
  EmbeddedTutor,
  LearnedTutor,
  UCSCMe,
} from "../assets/aboutMe";
import {
  Parallax,
  ParallaxBanner,
  ParallaxBannerLayer,
} from "react-scroll-parallax";

function Experience() {
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 660;

  React.useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);
  return (
    <div>
      <Header></Header>
      <ParallaxBanner style={{ aspectRatio: "2 / 1" }}>
        <ParallaxBannerLayer speed={-10} translateY={[-10, 10]}>
          <img
            src={ucsc}
            alt="..loading"
            style={{ width: "100%", opacity: "80%" }}
          />
        </ParallaxBannerLayer>
      </ParallaxBanner>
      <Parallax>
        <div className="School-container">
          <div className="MPC">
            <img
              src={ucscLogo}
              alt="..loading"
              className={`UCSC-logo${width < breakpoint ? "-mobile" : ""}`}
            ></img>
            <p
              style={{
                fontSize: 23,
                color: "black",
                padding: "2%",
                paddingLeft: "13%",
                paddingRight: "13%",
                wordSpacing: 1.5,
                whiteSpace: "pre-wrap",
                fontFamily: "Acron",
              }}
            >
              {UCSCMe}
            </p>
            <p className={`CC-container`}>Relevant Coursework from UCSC</p>
            {width > breakpoint ? (
              <div className="Coursework-container">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flex: "33%",
                    flexDirection: "column",
                  }}
                >
                  <ul
                    style={{
                      fontSize: 20,
                      display: "inline-block",
                      textAlign: "left",
                      fontFamily: "Acron",
                    }}
                  >
                    <li>Computer Systems and C Programming</li>
                    <li>Intro to Probibility Theory</li>
                    <li>Data Structures and Algorithms</li>
                  </ul>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flex: "33%",
                    flexDirection: "column",
                  }}
                >
                  <ul
                    style={{
                      fontSize: 20,
                      display: "inline-block",
                      textAlign: "left",
                      fontFamily: "Acron",
                    }}
                  >
                    <li>Computer Architecture</li>
                    <li>Statistics</li>
                  </ul>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flex: "33%",
                    flexDirection: "column",
                  }}
                >
                  <ul
                    style={{
                      fontSize: 20,
                      display: "inline-block",
                      textAlign: "left",
                      fontFamily: "Acron",
                    }}
                  >
                    <li>Principles of Computer Systems Design</li>
                    <li>Classical and Bayesian Inference</li>
                    <li>Introduction to Software Engineering</li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className="Coursework-container">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flex: "33%",
                    flexDirection: "column",
                  }}
                >
                  <ul
                    style={{
                      fontSize: 20,
                      display: "inline-block",
                      textAlign: "left",
                      fontFamily: "Acron",
                    }}
                  >
                    <li>Principles of Computer Systems Design</li>
                    <li>Classical and Bayesian Inference</li>
                    <li>Introduction to Software Engineering</li>
                    <li>Computer Systems and C Programming</li>
                    <li>Intro to Probibility Theory</li>
                    <li>Data Structures and Algorithms</li>
                    <li>Computer Architecture</li>
                    <li>Statistics</li>
                  </ul>
                </div>
              </div>
            )}
          </div>
          <Divider sx={{ borderBottomWidth: 5 }} style={{ width: "100%" }} />
          <div className={`MPC`}>
            <img
              src={mpc}
              alt="..loading"
              className={`UCSC-logo${width < breakpoint ? "-mobile" : ""}`}
            ></img>
            <p
              style={{
                fontSize: 23,
                color: "black",
                paddingRight: "15%",
                paddingLeft: "15%",
                paddingTop: "5%",
                paddingBottom: "5%",
                wordSpacing: 1.5,
                fontFamily: "Acron",
              }}
            >
              {MPCMe}
            </p>
            <div
              className={`Award-container${
                width < breakpoint ? "-mobile" : ""
              }`}
            >
              <img
                src={calcAward}
                alt="..Loading"
                className={`Award-image${width < breakpoint ? "-mobile" : ""}`}
              />
              <p
                style={{
                  fontSize: 23,
                  color: "black",
                  padding: "2%",
                  wordSpacing: 1.5,
                  fontFamily: "Acron",
                }}
              >
                {STEMTutor}
              </p>
            </div>
            {width > breakpoint ? (
              <div
                className={`Award-container${
                  width < breakpoint ? "-mobile" : ""
                }`}
              >
                <p
                  style={{
                    fontSize: 23,
                    color: "black",
                    padding: "2%",
                    wordSpacing: 1.5,
                    whiteSpace: "pre-wrap",
                    fontFamily: "Acron",
                  }}
                >
                  {EmbeddedTutor}
                  {"\n\n"}
                  {LearnedTutor}
                </p>
                <img
                  src={MPCAward}
                  alt="..Loading"
                  className={`Award-image${
                    width < breakpoint ? "-mobile" : ""
                  }`}
                />
              </div>
            ) : (
              <div
                className={`Award-container${
                  width < breakpoint ? "-mobile" : ""
                }`}
              >
                <img
                  src={MPCAward}
                  alt="..Loading"
                  className={`Award-image${
                    width < breakpoint ? "-mobile" : ""
                  }`}
                />
                <p
                  style={{
                    fontSize: 23,
                    color: "black",
                    padding: "2%",
                    wordSpacing: 1.5,
                    whiteSpace: "pre-wrap",
                    fontFamily: "Acron",
                  }}
                >
                  {EmbeddedTutor}
                  {"\n\n"}
                  {LearnedTutor}
                </p>
              </div>
            )}
            <Divider sx={{ borderBottomWidth: 5 }} style={{ width: "100%" }} />
            <p className={`CC-container`}>
              Relevant Coursework from Community College
            </p>
            <div
              className={`Coursework-container${
                width < breakpoint ? "-mobile" : ""
              }`}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flex: "33%",
                  flexDirection: "column",
                }}
              >
                <img
                  src={MPCLogo}
                  alt="..loading"
                  style={{
                    height: "70%",
                    width: "20%",
                    borderRadius: "100%",
                  }}
                />
                <ul
                  style={{
                    fontSize: 20,
                    display: "inline-block",
                    textAlign: "left",
                    fontFamily: "Acron",
                  }}
                >
                  <li>Calculus A/B/C</li>
                  <li>Linear Algebra</li>
                  <li>Java A/B</li>
                </ul>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flex: "33%",
                  flexDirection: "column",
                }}
              >
                <img
                  src={MCLogo}
                  alt="..loading"
                  style={{ height: "70%", width: "20%", borderRadius: "10%" }}
                />
                <ul
                  style={{
                    fontSize: 20,
                    display: "inline-block",
                    textAlign: "left",
                    fontFamily: "Acron",
                  }}
                >
                  <li>Physics A/B/C</li>
                  <li>Introductions to Computer Systems</li>
                </ul>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flex: "33%",
                  flexDirection: "column",
                }}
              >
                <img
                  src={CSMLogo}
                  alt="..loading"
                  style={{ height: "70%", width: "20%", borderRadius: "10%" }}
                />
                <ul
                  style={{
                    fontSize: 20,
                    display: "inline-block",
                    textAlign: "left",
                    fontFamily: "Acron",
                  }}
                >
                  <li>Descrete Mathematics</li>
                  <li>Data Structures: Java</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Parallax>
    </div>
  );
}

export default Experience;
