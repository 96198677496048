import React from "react";
import courts from "../assets/courts.gif";
import "./pickleballSlide.css";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import MapIcon from "@mui/icons-material/Map";

function PickleballSlide(props) {
  const courtsInfo =
    "In the Kitchen uses Google Maps API and Google Places autocomplete " +
    "so players can find and view pickleball courts. Users can review and upload new courts" +
    " to our database.";
  return (
    <div>
      <div
        className={`Slide-container${props.width < 620 ? "-mobile" : ""}`}
        style={{ padding: 40 }}
      >
        <img
          src={courts}
          alt="loading..."
          style={{ maxHeight: 1400, maxWidth: 220, padding: 30 }}
        ></img>
        <div>
          <p
            style={{
              textAlign: "center",
              fontSize: 35,
              color: "white",
              fontFamily: "Acron",
            }}
          >
            Upload and view Pickleball courts!
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignContent: "center",
            }}
          >
            <AddLocationAltIcon
              style={{ color: "orange", transform: "scale(3)" }}
            />
            <MapIcon style={{ color: "orange", transform: "scale(3)" }} />
          </div>
          <p
            className="Text"
            style={{ textAlign: "center", fontFamily: "Acron", wordSpacing: 2 }}
          >
            {courtsInfo}
          </p>
        </div>
      </div>
    </div>
  );
}

export default PickleballSlide;
