import React from "react";
import Header from "../components/header";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./project.css";
import PickleballSlide from "../components/pickleballSlide";
import PickleballSlide2 from "../components/pickleballSlide2";
import PickleballSlide3 from "../components/pickleballSlide3";
import PickleballSlide4 from "../components/pickleballSlide4";
import "@fontsource/tajawal";
import "@fontsource/abril-fatface";

function Project() {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);
  return (
    <>
      <Header></Header>
      <p
        className="Title"
        style={{
          marginBottom: 5,
          marginTop: 0,
          fontSize: 50,
          fontFamily: "Abril Fatface",
        }}
      >
        In the Kitchen: an IOS App
      </p>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          pauseOnMouseEnter: true,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
      >
        <SwiperSlide>
          <PickleballSlide width={width} />
        </SwiperSlide>
        <SwiperSlide>
          <PickleballSlide4 width={width} />
        </SwiperSlide>
        <SwiperSlide>
          <PickleballSlide3 width={width} />
        </SwiperSlide>
        <SwiperSlide>
          <PickleballSlide2 width={width} />
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default Project;
