import React from "react";
import Header from "../components/header";
import "./contact.css";
import ContactInfo from "../components/contactInfo";

function Contact() {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  return (
    <div>
      <Header></Header>
      <div>
        <ContactInfo width={width}></ContactInfo>
      </div>
    </div>
  );
}

export default Contact;
