import React from "react";
import messages from "../assets/messaging.gif";
import "./pickleballSlide.css";
import MessageIcon from "@mui/icons-material/Message";

function PickleballSlide(props) {
  const messaging =
    "Stay in touch with friends by direct messaging or creating groups chats. Messaging implemented with Socket.io and message persistance to MongoDB.";
  return (
    <div>
      <div
        className={`Slide-container${props.width < 620 ? "-mobile" : ""}`}
        style={{ padding: 40 }}
      >
        <img
          src={messages}
          alt="loading..."
          style={{ maxHeight: 1400, maxWidth: 220, padding: 30 }}
        ></img>
        <div>
          <p
            style={{
              textAlign: "center",
              fontSize: 35,
              color: "white",
              fontFamily: "Acron",
            }}
          >
            Direct messaging with Socket.io
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignContent: "center",
            }}
          >
            <MessageIcon style={{ color: "orange", transform: "scale(3)" }} />
          </div>
          <p
            className="Text"
            style={{ textAlign: "center", fontFamily: "Acron", wordSpacing: 2 }}
          >
            {messaging}
          </p>
        </div>
      </div>
    </div>
  );
}

export default PickleballSlide;
