import React from "react";
import me from "../assets/me.webp";
import "./home.css";
import Header from "../components/header";
import "@fontsource/abril-fatface";

function Home() {
  const cacheImages = async (srcArray) => {
    const p = await srcArray.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image();
        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(p);
  };

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 660;

  React.useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  React.useEffect(() => {
    const gifs = [
      "src/assets/courts.gif",
      "src/assets/home.gif",
      "src/assets/messaging.gif",
      "src/assets/profile.gif",
    ];
    cacheImages(gifs);
  });

  const text =
    ",\n a computer science major minoring in statistics, currently attending UCSC.\n";
  return (
    <div>
      <Header></Header>
      <div className={`Main-container${width < breakpoint ? "-mobile" : ""}`}>
        <img
          className={`Home-pic${width < breakpoint ? "-mobile" : ""}`}
          src={me}
          alt="loading..."
        />
        <div className="Text">
          <p style={{ fontSize: 40, fontFamily: "Acron" }}>
            I'm
            <span style={{ color: "#EF9129", fontFamily: "Abril Fatface" }}>
              {" "}
              Nicholas Barsi-Rhyne
            </span>
            {text}
          </p>
          <p style={{ fontSize: 30, fontFamily: "Acron" }}>
            With a strong background in
            <span style={{ color: "#EF9129", fontFamily: "Abril Fatface" }}>
              {" "}
              mathematics
            </span>
            ,
            <span style={{ color: "#EF9129", fontFamily: "Abril Fatface" }}>
              {" "}
              statistics
            </span>
            , and
            <span style={{ color: "#EF9129", fontFamily: "Abril Fatface" }}>
              {" "}
              programming
            </span>
            , I strive to unlock valuable insights from complex datasets.
          </p>
        </div>
      </div>
    </div>
  );
}
export default Home;
