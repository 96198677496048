import React from "react";
import profile from "../assets/profile.gif";
import "./pickleballSlide.css";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PeopleIcon from "@mui/icons-material/People";

function PickleballSlide(props) {
  const aboutITK =
    "Built with React Native, In the Kitchen is a social mobile app that helps connects pickleball players with their friends and nearby courts. It features a " +
    "editable profile page where users can set their picture and bio. ";
  return (
    <div>
      <div
        className={`Slide-container${props.width < 620 ? "-mobile" : ""}`}
        style={{ padding: 40 }}
      >
        <img
          src={profile}
          alt="loading..."
          style={{ maxHeight: 1400, maxWidth: 220, padding: 30 }}
        ></img>
        <div>
          <p
            style={{
              textAlign: "center",
              fontSize: 35,
              color: "white",
              fontFamily: "Acron",
            }}
          >
            Customizable accounts and friends!
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignContent: "center",
            }}
          >
            <AccountBoxIcon
              style={{ color: "orange", transform: "scale(3)" }}
            />
            <PeopleIcon style={{ color: "orange", transform: "scale(3)" }} />
          </div>
          <p
            className="Text"
            style={{
              textAlign: "center",
              fontFamily: "Acron",
              wordSpacing: 2,
            }}
          >
            {aboutITK}
          </p>
        </div>
      </div>
    </div>
  );
}

export default PickleballSlide;
