import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./header.css";
import "@fontsource/abril-fatface";
import "@fontsource/tajawal";
import HeaderMobile from "./headerMobile";

const trans = { background: "transparent" };
const highlight = { background: "#6d5bf3" };

function Header() {
  const [bColor, setbColor] = useState(trans);
  const [b1Color, setb1Color] = useState(trans);
  const [b2Color, setb2Color] = useState(trans);
  const [b3Color, setb3Color] = useState(trans);

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 620;

  React.useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  return (
    <>
      {width > breakpoint ? (
        <div className="App-header">
          <div className="Home-container">
            <h1
              className="Name"
              style={{
                color: "orange",
                fontFamily: "Abril Fatface",
                fontSize: 40,
              }}
            >
              Nick.B
            </h1>
            <div className="Home-link">
              <Link
                to="/"
                style={{
                  marginLeft: 20,
                  textDecoration: "none",
                }}
              >
                <h1
                  style={{
                    fontSize: 20,
                    color: "white",
                    fontFamily: "Tajawal",
                  }}
                >
                  Home
                </h1>
              </Link>
            </div>
          </div>
          <div className="Button-container">
            <Link
              to="/about"
              style={bColor}
              className="Button"
              onMouseEnter={() => setbColor(highlight)}
              onMouseLeave={() => setbColor(trans)}
            >
              <p
                style={{
                  color: "white",
                  borderWidth: 0,
                  width: "100%",
                  height: "100%",
                  fontSize: 20,
                  fontFamily: "Tajawal",
                }}
              >
                About Me
              </p>
            </Link>
            <Link
              to="/project"
              style={b1Color}
              className="Button"
              onMouseEnter={() => setb1Color(highlight)}
              onMouseLeave={() => setb1Color(trans)}
            >
              <p
                style={{
                  backgroundColor: "transparent",
                  color: "white",
                  borderWidth: 0,
                  width: "100%",
                  height: "100%",
                  fontSize: 20,
                  fontFamily: "Tajawal",
                }}
              >
                Projects
              </p>
            </Link>
            <Link
              to="/experience"
              style={b2Color}
              className="Button"
              onMouseEnter={() => setb2Color(highlight)}
              onMouseLeave={() => setb2Color(trans)}
            >
              <p
                style={{
                  backgroundColor: "transparent",
                  color: "white",
                  borderWidth: 0,
                  width: "100%",
                  height: "100%",
                  fontSize: 20,
                  fontFamily: "Tajawal",
                }}
              >
                Academics
              </p>
            </Link>
            <Link
              to="/contact"
              style={b3Color}
              className="Button"
              onMouseEnter={() => setb3Color(highlight)}
              onMouseLeave={() => setb3Color(trans)}
            >
              <p
                style={{
                  backgroundColor: "transparent",
                  color: "white",
                  borderWidth: 0,
                  width: "100%",
                  height: "100%",
                  fontSize: 20,
                  fontFamily: "Tajawal",
                }}
              >
                Contact
              </p>
            </Link>
          </div>
        </div>
      ) : (
        <HeaderMobile />
      )}
    </>
  );
}

export default Header;
