export const basicMe =
  "As a professional figure skater turned software developer, I've seamlessly transitioned from gliding gracefully on the ice to crafting cutting-edge software solutions. My passion for figure skating and technology has driven me throughout my career, and I approach every challenge with utmost dedication and hard work.\n\nThe world of artificial intelligence has always fascinated me, and I constantly keep an eye out for new technologies that I can implement in my projects. I'm a tireless learner, and natural problem solver, and I'm determined to make a significant impact in the tech industry.";
export const schoolMe =
  "I'm currently a student attending the University of California, Santa Cruz—pursuing a Bachelor of Science in Computer Science and complementing my passion with a minor in Statistics.\n\nI have an inquisitive nature and collaborative spirit that makes me a valuable asset in group projects, and my ability to think critically allows me to navigate complexities with ease. I transferred from Monterey Peninsula College in 2022 where I averaged 20 units a quarter while working part-time as a STEM tutor and an embedded tutor for Calculus 2.\n\nI'm currently going into my senior year and once I graduate I plan on pursuing a master's in data science.";
export const skatingMe =
  "When I was 6 years old, my parents decided to enroll me in figure skating classes, and for the next 20 years, I fully dedicated myself to the sport. I've represented the United States of America, competing nationally and internationally, ranking 8th in the country. I also had the privilage of giving back to the sports community by teaching and mentoring the next generation of figure skaters for over five years during that time.\n\nAfter retiring from competition and coaching, my partner and I performed worldwide with Bietak Productions. In the downtime between shows, I began to pursue a lifelong passion of mine: software development.";
export const MPCMe =
  "I first enrolled at Monterey Peninsula College in the fall of 2018. However, I was still performing worldwide as a figure skater at the time, and only once I retired in early 2020 did I begin to take more classes. From the spring of 2021 to the summer of 2022, I began taking the majority of my science classes, attending 3 different community colleges in order for this schedule to work. During this time, I was also working part-time as either a receptionist or as a STEM tutor at Monterey Peninsula College.";
export const STEMTutor =
  "I worked as a STEM tutor during the spring of 2022 and it was my pleasure to help students with questions related to physics, math, and computer science. I hosted around 10 hours of drop-in tutoring sessions a week. My sessions were usually online one-on-one interactions with students, helping them understand and solve difficult problems.";
export const EmbeddedTutor =
  "I also worked as an embedded tutor for Calculus 2 during the spring of 2022. This job involved hosting in-person group study sessions 2 to 3 times a week, weekly meetings with the professor, attending the student's lectures, and creating study plans for my meetings. After finishing the semester I was awarded a certificate by my supervision for helping the students I was tutoring excel in their calculus class.";
export const LearnedTutor =
  "While working as a tutor, I learned various valuable skills. Most importantly, I gained a deep understanding of effective communication, as I had to convey complex concepts in a clear and relatable manner. I also gained leadership and mentoring qualities, as I was able to empower students and guide them toward academic success.";
export const UCSCMe =
  "After completing my Associate Degree for Transfer at Monterey Peninsula College I transferred to the University of Santa Cruz in the fall of 2022. Currently, my combined GPA is 3.82 and I’m currently pursuing a major in computer science with a minor in statistics. So far I’ve enjoyed my time there with some of my favorite classes being Principles of Computer System Design and Classical and Bayesian Inference. I am also active in the Slug Gaming Club and a part of the collegiate Rocket League team, representing the school every quarter.\n\nMy next quarter, fall of 2023, is one I’m looking forward to the most since I will be taking a machine learning class as well as an applied machine learning class.";
export const contactMeText =
  "I’m enthusiastic to forge connections in the realms of software and data science, I welcome all networking opportunities. Feel free to reach out to me for engaging discussions and collaborations.";
