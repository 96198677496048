import React from "react";
import "./contactInfo.css";
import resume from "../assets/Resume.pdf";
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { contactMeText } from "../assets/aboutMe";

const itemHeight = {
  height: 90,
  bgcolor: `rgba(64, 107, 169, .5)`,
  display: "flex",
  width: "100%",
  borderRadius: 15,
};
const iconSize = { fontSize: 65, paddingRight: 5 };

const threashHold = 690;

const contactList = (
  <div className="Contact-list">
    <List
      sx={{
        width: "100%",
        height: "100%",
        maxWidth: 460,
        borderRadius: "5%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ListItemButton
        component="a"
        href={resume}
        target="_blank"
        rel="noreferrer"
        sx={itemHeight}
      >
        <ListItemIcon>
          <ListAltIcon className="Icon" sx={iconSize} />
        </ListItemIcon>
        <ListItemText className="Icon-text" primary="View my resume" />
      </ListItemButton>
      <Divider />
      <ListItemButton
        sx={itemHeight}
        component="a"
        onClick={() => (window.location = "mailto:yourmail@domain.com")}
      >
        <ListItemIcon>
          <EmailIcon className="Icon" sx={iconSize} />
        </ListItemIcon>
        <ListItemText
          className="Icon-text"
          primary="Email Me: nbarsirh@ucsc.edu"
        />
      </ListItemButton>
      <Divider />
      <ListItemButton
        sx={itemHeight}
        component="a"
        href="https://github.com/mrplooply"
        target="_blank"
        rel="noreferrer"
      >
        <ListItemIcon>
          <GitHubIcon className="Icon" sx={iconSize} />
        </ListItemIcon>
        <ListItemText className="Icon-text" primary="Find me on GitHub" />
      </ListItemButton>
      <Divider />
      <ListItemButton
        sx={itemHeight}
        component="a"
        href="https://www.linkedin.com/in/nicholas-barsi-rhyne-64a006204/"
        target="_blank"
        rel="noreferrer"
      >
        <ListItemIcon>
          <LinkedInIcon className="Icon" sx={iconSize} />
        </ListItemIcon>
        <ListItemText className="Icon-text" primary="Find me on LinkedIn" />
      </ListItemButton>
    </List>
  </div>
);

function ContactInfo(props) {
  return (
    <div className="Contact-container">
      <div className="Info-container">
        <p className="Contact-header">Contact Me</p>
        {props.width < threashHold && contactList}
        <p className="Contact-text">{contactMeText}</p>
      </div>
      {props.width > threashHold && contactList}
    </div>
  );
}

export default ContactInfo;
